import React from 'react'

import { FaCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'

const IconMark = ({ size }) => {
  return (
    <span
      style={{
        position: 'absolute',
        display: 'block',
        top: '60%',
        left: -5,
        bottom: 0,
        height: size,
        width: size
      }}
    >
      <IconContext.Provider value={{ color: 'white', size }}>
        <FaCircle
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0
          }}
        />
      </IconContext.Provider>
      <svg
        style={{
          position: 'absolute',
          left: 0
          //bottom: 0
        }}
        viewBox="0 0 426.667 426.667"
      >
        <path
          d="M213.333 0C95.518 0 0 95.514 0 213.333s95.518 213.333 213.333 213.333c117.828 0 213.333-95.514 213.333-213.333S331.157 0 213.333 0zm-39.134 322.918l-93.935-93.931 31.309-31.309 62.626 62.622 140.894-140.898 31.309 31.309-172.203 172.207z"
          fill="#6ac259"
        />
      </svg>
    </span>
  )
}

export default IconMark
