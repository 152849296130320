import React from 'react'

import { MdReplay } from 'react-icons/md'
import { FaCircle } from 'react-icons/fa'
import { IconContext } from 'react-icons'

const IconReplay = ({ onClick }) => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'inline',
        height: '2em',
        width: '2em',
        marginLeft: 10,
        cursor: 'pointer'
      }}
      onClick={onClick}
    >
      <IconContext.Provider value={{ color: '#434343', size: '2em' }}>
        <FaCircle
          style={{
            position: 'absolute',
            left: 0,
            top: 0
          }}
        />
      </IconContext.Provider>
      <IconContext.Provider value={{ color: 'white', size: '1.5em' }}>
        <MdReplay
          style={{
            position: 'absolute',
            left: '0.25em',
            top: '0.25em'
          }}
        />
      </IconContext.Provider>
    </div>
  )
}

export default IconReplay
